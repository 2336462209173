import Vue from 'vue';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import './plugins/vee-validate';
import VueTheMask from 'vue-the-mask';
import 'leaflet/dist/leaflet.css';

Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.config.devtools = false;

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app');
