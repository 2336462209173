import api from '../../../plugins/api';
import { checkError, cloneDeep, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library/utils';
import { usersTenants } from '@/plugins/enums';

const template = {
  id: 0,
  type: 4,
  name: null,
  taxId: null,
  email: null,
  phones: [ {} ],
  tenants: [ { type: null } ],
  addresses: [
    {
      address: {
        zipCode: undefined,
        city: undefined,
        state: undefined,
        address: undefined,
        number: undefined,
      },
    },
  ],
}

const state = {
  tableLoading: false,
  allTenantCollaborators: [],
  registerTenantCollaborator: cloneDeep(template),
  tenantCollaboratorsRoles: [
    {
      id: 1,
      label: 'Administrador'
    },
    {
      id: 2,
      label: 'Colaborador'
    }
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allTenantCollaborators = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!!payload) {
      payload.addresses = parseAddressToApp(payload);
      payload.phones = (payload.phones) ? payload.phones : [ {} ];
      state.registerTenantCollaborator = payload;

    } else {
      state.registerTenantCollaborator = cloneDeep(template);
    }
  },
  CREATE_PHONE(state) {
    state.registerTenantCollaborator.phones.push({});
  },
  DELETE_PHONE(state, index) {
    state.registerTenantCollaborator.phones.splice(index, 1);
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('users', payload)
           .then(response => {
              resolve(response.data);;
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, name, taxId, email, tenants{type}');
    query['tenants{type}'] = '{' + usersTenants.TYPE_ADMIN + ', ' +  usersTenants.TYPE_USER + '}';

    return new Promise((resolve, reject) => {
      api.get('users?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('users/' + id)
           .then(response => {
             commit('SET', response.data);
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.id, payload).then(response => {
         resolve(response.data);;
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('users/' + id)
           .then((response) => {
              resolve(response.data);;
           })
           .catch((error) => {
             checkError(error.response.status);
             reject(error.response);
           });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
