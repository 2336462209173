import api from '../../../plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, prepareApiParams } from 'erp-skeleton-library/utils';
import { classesStatus } from '@/plugins/enums';

const template = {
  id: 0,
  city: null,
  state: null,
  tenant: null,
  tenantId: null,
  unhired: false,
  private: false,
  site: false,
  dateStart: null,
  dateEnd: null,
  instructor: null,
  students: [],
  dates: [],
  status: { id: 1, type: classesStatus.TYPE_PLANNED },
  course: {
    id: null,
    workload: null,
  },
  classSite: {
    maximumTermInstallments: null,
    maximumStudents: null,
    minimumStudents: null,
    partnership: null,
    price: null,
  },
  periods: [
    {
      period: null,
    },
  ],
  tasks: [
    {
      status: null,
    },
  ],
};

const state = {
  tableLoading: false,
  allClasses: [],
  allStatusClasses: [],
  registerClasses: cloneDeep(template),
  classesPeriods: [
    {
      id: 1,
      label: 'Matutino',
    },
    {
      id: 2,
      label: 'Vespertino',
    },
    {
      id: 3,
      label: 'Noturno',
    },
    {
      id: 4,
      label: 'Integral',
    },
  ],
  headers: [
    {
      text: 'ID',
      align: 'left',
      value: 'id',
    },
    {
      text: 'CE',
      align: 'left',
      value: 'tenantId',
    },
    {
      text: 'Curso',
      align: 'left',
      value: 'course.name',
    },
    {
      text: 'Cidade',
      align: 'left',
      value: 'city.name',
    },
    {
      text: 'Status',
      align: 'left',
      value: 'status.name',
    },
    {
      text: 'Início',
      align: 'left',
      value: 'dateStart',
    },
    {
      text: 'Término',
      align: 'left',
      value: 'dateEnd',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allClasses = payload;
    state.tableLoading = false;
  },
  LIST_STATUS(state, payload) {
    state.allStatusClasses = payload;
  },
  SET(state, payload) {
    if (!!payload) {
      payload.dateStart = payload.dateStart.split(' ')[0];
      payload.dateEnd = payload.dateEnd.split(' ')[0];

      if (!isNullOrEmpty(payload.dates)) {
        payload.dates.forEach(date => date.date = date.date.split(' ')[0]);
      } else {
        payload.dates = [];
      }

      if (!payload.site) {
        payload.classSite = cloneDeep(template.classSite);
      } else {
        payload.classSite.maximumTermInstallments = payload.classSite?.maximumTermInstallments?.id ?? payload.classSite?.maximumTermInstallments ?? null;
      }

      if (!payload.periods) {
        payload.periods = cloneDeep(template.periods);
      }

      if (!payload.students) {
        payload.students = cloneDeep(template.students);
      }

      state.registerClasses = payload;
    } else {
      state.registerClasses = cloneDeep(template);
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  addStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes/' + payload.class + '/students', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  changeStatus({ commit }, payload) {
    let entity = {
      status: payload.status,
      canceledReason: payload.canceledReason ? (payload.canceledReason) : null,
    };

    return new Promise((resolve, reject) => {
      api.post('classes/' + payload.class + '/status', entity)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, dateStart, dateEnd, status, city, course, tenantId, canceledBy');

    return new Promise((resolve, reject) => {
      api
        .get('classes?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  listStatus({ commit }) {
    let query = prepareApiParams(null, 'id, name, type, color');

    return new Promise((resolve, reject) => {
      api.get('classes/statuses?' + new URLSearchParams(query).toString())
         .then(response => {
           commit('LIST_STATUS', response.data.entities);
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('classes/' + id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        console.log(error);
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('/classes/' + payload.class + '/files/' + payload.id)
         .then(resolve)
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('classes/' + payload.id, payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('classes/' + id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  deleteStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.delete('classes/' + payload.class + '/students/' + payload.id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
