import api from '@/plugins/api';
import  { checkError } from 'erp-skeleton-library/utils';
import {reportType} from "@/plugins/enums";

const state = {
  reportsTypes: [
    {
      id: reportType.TYPE_BASE,
      label: 'Treinamento base',
      pra: false
    },
    {
      id: reportType.TYPE_STUDENT,
      label: 'Alunos treinados',
      pra: false
    },
    {
      id: 3,
      label: 'Técnicos com documentos a vencer',
      pra: true
    },
  ],
};

const actions = {
  createBase({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('reports/classes/base', payload)
           .then(response => {
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  createStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('reports/classes/trained-students', payload)
          .then(response => {
            resolve(response.data);
          }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
};
