export const usersTenants = {
  TYPE_ADMIN: 1,
  TYPE_USER: 2,
  TYPE_SUPERVISOR: 3,
  TYPE_TECHNICIAN: 4,
};

export const userType = {
  TYPE_SYSTEM: 1,
  TYPE_ADMIN: 2,
  TYPE_SUPPORT: 3,
  TYPE_USER: 4,
  TYPE_INSTRUCTOR: 5,
};

export const courseFilesType = {
  TYPE_CLASS: 1,
  TYPE_THEORETICAL: 2,
  TYPE_PRATICAL: 3
}

export const classesStatus = {
  TYPE_PLANNED: 1,
  TYPE_IN_PROGRESS: 2,
  TYPE_CLOSED: 3,
  TYPE_FINISHED: 4,
  TYPE_CANCELED: 5,
}

export const serviceOrderStatus = {
  TYPE_REGISTERED: 1,
  TYPE_IN_PROGRESS: 2,
  TYPE_FINISHED: 3,
  TYPE_REFUSED: 4,
  TYPE_CANCELED: 5,
}

export const serviceOrderPurpose = {
  TYPE_INSTALLATION: 1,
  TYPE_INFRASTRUCTURE: 2,
  TYPE_TECHNICAL_VISIT: 3,
  TYPE_ADEQUACY: 4,
}

export const statusClassStudent = {
  TYPE_APPROVED: 1,
  TYPE_ABSENT: 2,
  TYPE_INSUFFICIENCY: 3
}

export const reportFileType = {
  TYPE_SCREEN: 1,
  TYPE_PDF: 2,
  TYPE_XLS: 3,
  TYPE_CSV: 4
}

export const reportType = {
  TYPE_BASE: 1,
  TYPE_STUDENT: 2
}

export const paymentMethodsType = {
  TYPE_METHOD_CREDIT: 2,
}
