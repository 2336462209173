import { checkError } from 'erp-skeleton-library/utils';
import api from '@/plugins/api';

const state = {
  tableLoading: false,
  allGrades: [],
  headers: [
    {
      text: 'CPF',
      align: 'left',
      value: 'taxId',
    },
    {
      text: 'Aluno',
      align: 'left',
      value: 'student',
    },
    {
      text: 'Nota',
      align: 'right',
      value: 'grade',

    },
  ],
};

const mutations = {
  SET(state, payload) {
    if (!!payload) {
      state.allGrades = payload;
    } else {
      state.allGrades = [];
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes/' + payload.class + '/grades/' + payload.classStudent, payload.grade)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  read({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('classes/' + payload + '/grades')
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
