import api from '../../../plugins/api';
import { checkError, cloneDeep, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library/utils';

const template = {
  id: 0,
  name: null,
  tradeName: null,
  taxId: null,
  email: null,
  phones: [ {} ],
  addresses: [
    {
      address: {
        zipCode: undefined,
        city: undefined,
        state: undefined,
        address: undefined,
        number: undefined,
      },
    },
  ],
};

const state = {
  tableLoading: false,
  allPartners: [],
  registerPartner: cloneDeep(template),
  headers: [
    {
      text: 'Razão social',
      align: 'left',
      value: 'name',
    },
    {
      text: 'Nome fantasia',
      align: 'left',
      value: 'tradeName',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allPartners = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!!payload) {
      payload.addresses = parseAddressToApp(payload);
      payload.phones = (payload.phones) ? payload.phones : [ {} ];
      state.registerPartner = payload;

    } else {
      state.registerPartner = cloneDeep(template);
    }
  },
  CREATE_PHONE(state) {
    state.registerPartner.phones.push({});
  },
  DELETE_PHONE(state, index) {
    state.registerPartner.phones.splice(index, 1);
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('partners', payload)
           .then(response => {
              resolve(response.data);;
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, name, tradeName');

    return new Promise((resolve, reject) => {
      api
        .get('partners?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('partners/' + id)
           .then(response => {
             commit('SET', response.data);
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('partners/' + payload.id, payload).then(response => {
         resolve(response.data);;
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('partners/' + id)
           .then((response) => {
              resolve(response.data);;
           })
           .catch((error) => {
             checkError(error.response.status);
             reject(error.response);
           });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
