import api from '@/plugins/api';
import { checkError, cloneDeep, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  allPraTasks: [],
  registerPraTask: {
    id: 0,
    name: null,
    order: null,
  },
};

const mutations = {
  SET(state, payload) {
    payload.risks.forEach(risk => risk.children = cloneDeep(risk.preventions));
    payload.children = cloneDeep(payload.risks);
    state.allPraTasks.push(payload);
  },
  CLEAR() {
    state.allPraTasks = [];
  }
};

const actions = {
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('pra/tasks/' + id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
