import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library/utils';
import { usersTenants } from '@/plugins/enums';

const template = {
  id: 0,
  type: 4,
  name: null,
  taxId: null,
  tenants: [
    {
      type: usersTenants.TYPE_TECHNICIAN,
      technician: { supervisor: null },
    },
  ],
  phones: [ {} ],
  addresses: [
    {
      address: {
        zipCode: undefined,
        city: undefined,
        state: undefined,
        address: undefined,
        number: undefined,
      },
    },
  ],
};

const state = {
  tableLoading: false,
  allTechnicians: [],
  registerTechnician: cloneDeep(template),
  headers: [
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
    },
    {
      text: 'CPF',
      align: 'left',
      value: 'taxId',
    },
    {
      text: 'Email',
      align: 'left',
      value: 'email',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allTechnicians = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!isNullOrEmpty(payload)) {
      payload.addresses = parseAddressToApp(payload);
      payload.phones = (payload.phones) ? payload.phones : [ {} ];
      payload.tenants[0].technician = isNullOrEmpty(payload.tenants[0].technician) ? cloneDeep(template.tenants[0].technician) : payload.tenants[0].technician;
      state.registerTechnician = payload;

    } else {
      state.registerTechnician = cloneDeep(template);
    }
  },
  CREATE_PHONE(state) {
    state.registerTechnician.phones.push({});
  },
  DELETE_PHONE(state, index) {
    state.registerTechnician.phones.splice(index, 1);
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('users', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id,taxId,email,name,tenants{type,name,tenantId, technician}');
    query['tenants{type}'] = usersTenants.TYPE_TECHNICIAN;

    return new Promise((resolve, reject) => {
      api
        .get('users?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  listDashboard({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id,name,document');

    return new Promise((resolve, reject) => {
      api.get('pra/technicians?' + new URLSearchParams(query).toString())
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('users/' + id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
           ;
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.id, payload).then(response => {
        resolve(response.data);
        ;
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('users/' + id)
         .then((response) => {
           resolve(response.data);
           ;
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
