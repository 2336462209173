import store from './store';
import { userType } from '@/plugins/enums';
import api from '@/plugins/api'
import { isNullOrEmpty } from 'erp-skeleton-library/utils';

export function loggedIn(state) {
  api.defaults.headers['Authorization'] = 'Bearer ' + state.baseStore.apiToken;
  return state.baseStore.apiToken !== null;
}

export function getUserType (state) {
  if (state.baseStore.appUser && state.baseStore.appUser.type !== userType.TYPE_USER) {
    return ({
      userType: parseInt(state.baseStore.appUser.type),
    });
  } else {
    return ({
      userType: parseInt(state.baseStore.appUser.type),
      tenantUserType: (!isNullOrEmpty(state.baseStore.appUser.tenants)) ? parseInt(state.baseStore.appUser.tenants[0].type) : null,
    });
  }
}

export function listCitiesbyState (id)  {
  return store.state.baseStore.cities.find(item => item.state === id);
}
