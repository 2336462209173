import api from '../../../plugins/api';
import { checkError, cloneDeep, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library/utils';

const template = {
  id: 0,
  name: null,
  tradeName: null,
  taxId: null,
  emails: [ {} ],
  phones: [ {} ],
  addresses: [
    {
      address: {
        zipCode: undefined,
        city: undefined,
        state: undefined,
        address: undefined,
        number: undefined,
      },
    },
  ],
  roles: [
    {type: 3}
  ]
};

const state = {
  tableLoading: false,
  allParties: [],
  registerParty: cloneDeep(template),
  headers: [
    {
      text: 'Razão social',
      align: 'left',
      value: 'name',
    },
    {
      text: 'Documento',
      align: 'left',
      value: 'taxId',
    },
    {
      text: 'Nome fantasia',
      align: 'left',
      value: 'tradeName',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allParties = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!!payload) {
      payload.addresses = parseAddressToApp(payload);
      payload.phones = (payload.phones) ? payload.phones : [ {} ];
      payload.emails = (payload.emails) ? payload.emails : [ {} ];
      state.registerParty = payload;
    } else {
      state.registerParty = cloneDeep(template);
    }
  },
  CREATE_EMAIL(state) {
    state.registerParty.emails.push({});
  },
  DELETE_EMAIL(state, index) {
    state.registerParty.emails.splice(index, 1);
  },
  CREATE_ADDRESS(state) {
    state.registerParty.addresses.push(template.addresses[0]);
  },
  DELETE_ADDRESS(state, index) {
    state.registerParty.addresses.splice(index, 1);
  },
  CREATE_PHONE(state) {
    state.registerParty.phones.push({});
  },
  DELETE_PHONE(state, index) {
    state.registerParty.phones.splice(index, 1);
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('parties', payload)
         .then(response => {
            resolve(response.data);;
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, name, taxId, tradeName, addresses');
    query['roles{type}'] = 3;

    return new Promise((resolve, reject) => {
      api.get('parties?' + new URLSearchParams(query).toString())
         .then(response => {
           commit('LIST', response.data.entities);
           state.tableLoading = false;
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('parties/' + id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('parties/' + payload.id, payload).then(response => {
         resolve(response.data);;
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('parties/' + id)
         .then((response) => {
            resolve(response.data);;
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
