import api from '../../../plugins/api';
import { checkError, cloneDeep, isNullOrEmpty } from 'erp-skeleton-library/utils';

const state = {
    allClassesFiles: [],
    registerClassesFile: {
        id: 0,
        name: null,
        fileExt: null,
        fileData: null,
    }
};

const mutations = {
    LIST(state, payload) {
        state.allClassesFiles = payload;
    },
    SET(state, payload) {
        if (isNullOrEmpty(payload)) {
            state.registerClassesFile = {
                id: 0,
                name: null,
                fileData: null,
            }
        } else {
            state.registerClassesFile = cloneDeep(payload);
        }
    },
};

const actions = {
    create({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.post('classes/' + payload.class + '/files', payload.file)
                .then(response => resolve(response.data))
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    list({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.get('classes/' + payload + '/files')
                .then(response => resolve(response.data))
                .catch(error => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
    listFileToClass({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.get('/classes/' + payload + '/in-class-files')
                .then((response) => resolve(response.data))
                .catch((error) => {
                    checkError(error.response.status);
                    reject(error.response);
                })
        })
    },
    read({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.get('classes/' + payload.class + '/files/' + payload.file)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    checkError(error.response.status);
                    reject(error.response);
                })
        })
    },
    downloadFile({commit}, path) {
        return new Promise((resolve, reject) => {
            api.get(path)
               .then((response) => {
                   resolve(response.data);
               })
               .catch((error) => {
                   checkError(error.response.status);
                   reject(error.response);
               })
        })
    },
    readFileToClass({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.get(payload)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    checkError(error.response.status);
                    reject(error.response);
                })
        })
    },
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.delete('users?id=' + id)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
