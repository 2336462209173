import { userType } from '@/plugins/enums';
import { usersTenants } from '@/plugins/enums';

export default {
  menusDashboard: [
    {
      id: '1',
      icon: 'mdiViewDashboard',
      name: 'Dashboard',
      href: '/dashboard',
      permissions: {
        userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
        tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR ],
      },
    },
    {
      id: '2',
      name: 'Clientes',
      icon: 'mdiAccountMultiple',
      href: '/customers',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
    {
      id: '80',
      isGroup: true,
      name: 'APR Digital',
      permissions: {
        userType: [ userType.TYPE_USER ],
        tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR],
        pra: true,
      },
    },
    {
      id: '3',
      name: 'Ordens de serviço',
      icon: 'mdiBookOpen',
      href: '/service-orders',
      permissions: {
        userType: [ userType.TYPE_USER ],
        tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR],
        pra: true,
      },
    },
    {
      id: '4',
      icon: 'mdiTextBox',
      name: 'Relatórios',
      href: '/pra/reports',
      permissions: {
        userType: [ userType.TYPE_USER ],
        tenantUserType: [ usersTenants.TYPE_ADMIN ],
        pra: true,
      },
    },
    {
      id: '5',
      icon: 'mdiNotebookEdit',
      name: 'Cadastros',
      href: '/pra/register',
      permissions: {
        userType: [ userType.TYPE_USER ],
        tenantUserType: [ usersTenants.TYPE_ADMIN ],
        pra: true,
      },
      children: [
        {
          id: '6',
          name: 'Clientes',
          href: '/pra/register/parties',
          permissions: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN ],
            pra: true,
          },
        },
        {
          id: '7',
          name: 'Colaboradores',
          href: '/pra/register/tenant/collaborators',
          permissions: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN ],
            pra: true,
          },
        },
        {
          id: '8',
          name: 'Supervisores',
          href: '/pra/register/supervisors',
          permissions: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN ],
            pra: true,
          },
        },
        {
          id: '9',
          name: 'Técnicos',
          href: '/pra/register/technicians',
          permissions: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN ],
            pra: true,
          },
        },
      ],
    },
    {
      id: '90',
      isGroup: true,
      name: 'Treinamentos',
      permissions: {
        userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
        tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
        student: true,
      },
    },
    {
      id: '10',
      name: 'Turmas',
      icon: 'mdiAccountGroup',
      href: '/classes',
      permissions: {
        userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
        tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
        student: true,
      },
    },
    {
      id: '11',
      name: 'Relatórios',
      icon: 'mdiTextBox',
      href: '/reports',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
    },
    {
      id: '12',
      icon: 'mdiNotebookEdit',
      name: 'Cadastros',
      href: '/register',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
      children: [

        {
          id: '13',
          name: 'Alunos',
          href: '/register/students',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '14',
          name: 'Instrutores',
          href: '/register/instructors',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '15',
          name: 'Parceiros',
          href: '/register/partners',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '16',
          name: 'Colaboradores',
          href: '/register/collaborators',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '17',
          name: 'Salas',
          href: '/register/places',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '18',
          name: 'Cursos',
          href: '/register/courses',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
      ],
    },
    {
      id: '19',
      icon: 'mdiCog',
      name: 'Configurações',
      href: '/config',
      permissions: {
        userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
      },
      children: [
        {
          id: '20',
          name: 'Tarefas (Checklists)',
          href: '/config/tasks',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
        {
          id: '21',
          name: 'Tipos de curso',
          href: '/config/courses/type',
          permissions: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
        },
      ],
    },
  ],
};
