import { extend } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Campo obrigatório',
});

extend('email', {
  ...email,
  message: 'O email deve ser válido',
});

extend('passwordConfirm', {
  params: [ 'target' ],
  validate(value, { target }) {
    return value === target;
  },
  message: 'As senhas devem ser iguais.',
});

extend('password', {
  validate(value) {
    return /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,}$/.test(value);
  },
  message: 'Mínimo 8 caracteres com uma letra minúscula e maiúscula, um número e um caracter especial [!@#$%].',
});

