import api from '@/plugins/api';
import { checkError, isNullOrEmpty, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  dashboardClasses: [],
  loadingDashboardCards: true,
};

const mutations = {
  SET_DASHBOARD_LOADING(state, payload) {
    state.loadingDashboardCards = payload;
  },
  SET_DASHBOARD_CARDS(state, payload) {
    if (payload[0]) {
      let findDashboardClasses = state.dashboardClasses.filter(item => payload[0].status === item.status);
      if (findDashboardClasses) {
        state.dashboardClasses = state.dashboardClasses.filter(item => payload[0].status !== item.status);
      }

      state.dashboardClasses.push(...payload);
    }
  },
  CLEAR_DASHBOARD_CARDS(state) {
    state.dashboardClasses = [];
  },
};

const actions = {
  list({ commit }, payload) {
    let query = prepareApiParams(payload, 'id,tenantId,period{name},dateStart,dateEnd,classes,dailyHourFrom,dailyHourTo,course{id,name,workload},site,state{abbreviation},city{name},instructor{id,name},classSite{partnership},canceledBy');
    query.status = payload.status;

    return new Promise((resolve, reject) => {
      api.get('classes?' + new URLSearchParams(query).toString())
         .then(response => resolve(response.data))
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  listMainDashboard({ commit }, payload) {
    let query = {};
    if (!isNullOrEmpty(payload)) {
      for (let filter in payload) {
        if(!isNullOrEmpty(payload[filter])) {
          query[filter] = payload[filter];
        }
      }
    }

    return new Promise((resolve, reject) => {
      api.get('/pra/dashboard?' + new URLSearchParams(query).toString())
         .then(response => resolve(response.data))
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
