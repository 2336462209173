import axios from 'axios';

const api = axios.create({
    baseURL: location.protocol + '//' + location.hostname.replace('app.', 'api.') + '/v1',
    headers: {
        'timezone-offset': '-03:00',
    },
});

api.interceptors.request.use(
    (request) => {
        if (sessionStorage.getItem('apiDebug')) {
            let beforeParam = (request.url.includes('?')) ? '&' : '?';
            request.url += beforeParam + 'XDEBUG_SESSION_START=' + sessionStorage.getItem('apiDebug')
        }

        return request;
    },
    (error) => console.log(error),
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.message.startsWith('Network')) {
            error = {
                response: {
                    status: 500,
                    data: {
                        message: error.message,
                    },
                },
            }
        }
        return Promise.reject(error);
    },
);

export default api;

