import api from '../../plugins/api';
import  { checkError, cloneDeep, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  tableLoading: false,
  allTasks: [],
  registerTask: {
    id: 0,
    name: null,
    order: null,
  },
  headers: [
    {
      text: 'Ordem de exibição',
      align: 'left',
      value: 'order',
    },
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allTasks = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (!!payload) {
      state.registerTask = cloneDeep(payload);
    } else {
      state.registerTask = {
        id: 0,
        name: null,
        order: null,
      };
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes/tasks', payload)
           .then(response => {
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, order, name');

    return new Promise((resolve, reject) => {
      api
        .get('classes/tasks?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('classes/tasks/' + id)
           .then(response => {
             commit('SET', response.data);
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('classes/tasks/' + payload.id, payload)
       .then(response => {
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('classes/tasks/' + id)
           .then((response) => {
             resolve(response.data);
           })
           .catch((error) => {
             checkError(error.response.status);
             reject(error.response);
           });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
