<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
:root {
  --primary-color: #017B52;
  --secondary-color: #696969;
}
</style>
