import Vue from 'vue';
import Vuex from 'vuex';
import state from './state';
import * as getters from './getters';
import dashboard from './modules/dashboard';
import customers from './modules/parties/customers';
import users from './modules/users';
import courses from './modules/courses/courses';
import coursesType from './modules/courses/courses-type';
import students from './modules/parties/students';
import partners from './modules/parties/partners';
import instructors from './modules/parties/instructors';
import collaborators from './modules/parties/collaborators';
import tenantCollaborators from './modules/parties/tenantCollaborators';
import payments from './modules/payments';
import supervisors from './modules/parties/supervisors';
import technicians from './modules/parties/technicians';
import parties from './modules/parties/parties';
import places from './modules/places';
import reports from './modules/reports';
import tasks from './modules/tasks';
import praTasks from './modules/pra-tasks';
import classes from './modules/classes/classes';
import classesAttendances from './modules/classes/attendances';
import classesGrades from './modules/classes/grades';
import certificates from './modules/certificates';
import classesFiles from './modules/classes/files';
import fileTypes from './modules/fileTypes';
import serviceOrders from './modules/service-order';
import monitoring from './modules/monitoring';
import { baseStore } from 'erp-skeleton-library/base-store/base-store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dashboard,
    customers,
    users,
    courses,
    coursesType,
    students,
    partners,
    instructors,
    collaborators,
    supervisors,
    technicians,
    parties,
    tenantCollaborators,
    places,
    payments,
    reports,
    tasks,
    praTasks,
    classes,
    classesAttendances,
    classesGrades,
    classesFiles,
    certificates,
    fileTypes,
    serviceOrders,
    monitoring,
    baseStore,
  },
  state,
  getters,
});

export default store;
