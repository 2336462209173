import api from '../../plugins/api';
import  { checkError, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  tableLoading: false,
  allFileTypes: [],
  registerFileType: {
    id: 0,
    role: null,
    name: null,
  },
  headers: [
    {
      text: 'Função',
      align: 'left',
      value: 'name',
      sortable: false,
    },
    {
      text: 'Nome',
      align: 'left',
      value: 'name',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allFileTypes = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('users', payload)
           .then(response => {
              resolve(response.data);;
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload);

    return new Promise((resolve, reject) => {
      api
        .get('users?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('users/' + id)
           .then(response => {
             commit('SET', response.data);
              resolve(response.data);;
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.id, payload).then(response => {
         resolve(response.data);;
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('users?id=' + id)
           .then((response) => {
              resolve(response.data);;
           })
           .catch((error) => {
             checkError(error.response.status);
             reject(error.response);
           });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
