import api from '../../../plugins/api';
import { checkError, cloneDeep } from 'erp-skeleton-library/utils';

const attendanceTemplate = {
    id: 0,
    classStudent: {
        id: 0,
        class: {
            id: 0
        },
        student: {
            id: 0,
            taxId: null,
            name: null
        }
    },
    date: {
        id: 0
    },
    present: null,
}

const dateTemplate = {
    id: 0,
    students: [attendanceTemplate],
    dailyNotes: null,
    attendanceFile: null,
    fileExt: null,
}

const state = {
    tableLoading: false,
    registerStudentsAttendances: {
        dates: [dateTemplate],
    },
    headers: [
        {
            text: 'CPF',
            align: 'left',
            value: 'taxId',
            sortable: false,
        },
        {
            text: 'Nome',
            align: 'left',
            value: 'name',
            sortable: false,
        },
        {
            text: 'Presença',
            align: 'right',
            value: 'presence',
            sortable: false,
        }
    ],
};

const mutations = {
    SET(state, payload) {
        if(payload) {
            for(let item of payload) {
                item.attendanceFile = (item.attendanceFile) ? atob(item.attendanceFile) : null;
            }
            state.registerStudentsAttendances.dates = cloneDeep(payload);
        } else {
            state.registerStudentsAttendances.dates = dateTemplate;
        }
    },
};

const actions = {
    create({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.post('classes/' + payload.class + '/attendances/' + payload.classDate + '/students/' + payload.classStudent, payload.data)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                checkError(error.response.status);
                reject(error.response);
            });
        });
    },
    read({commit}, id) {
        return new Promise((resolve, reject) => {
            api.get('classes/' + id + '/attendances')
                .then(response => {
                    commit('SET', response.data);
                    resolve(response.data);
                }).catch(error => {
                    console.log(error)
                checkError(error.response.status);
                reject(error.response);
            });
        });
    },
    readFile({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.get('classes/' + payload.class + '/files/attendances/' + payload.date)
                .then(response => {
                    resolve(response.data);
                }).catch(error => {
                checkError(error.response.status);
                reject(error.response);
            });
        });
    },
    update({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.put('classes/' + payload.class + '/attendances/' + payload.id, payload.attendance)
                .then(response => {
                resolve(response.data);
            }).catch(error => {
                checkError(error.response.status);
                reject(error.response);
            });
        });
    },
    delete({commit}, id) {
        return new Promise((resolve, reject) => {
            api.delete('users?id=' + id)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    checkError(error.response.status);
                    reject(error.response);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
