import api from '@/plugins/api';
import { checkError, cloneDeep, prepareApiParams } from 'erp-skeleton-library/utils';
import { courseFilesType } from '@/plugins/enums';

const template = {
  type: null,
  name: null,
  workload: null,
  validity: null,
  programContent: null,
  classFiles: [
    {
      name: null,
      fileData: null,
      type: courseFilesType.TYPE_CLASS,
    },
  ],
  theoreticalFile: [
    {
      name: null,
      fileData: null,
      type: courseFilesType.TYPE_THEORETICAL,
    },
  ],
  praticalFile: [
    {
      name: null,
      fileData: null,
      type: courseFilesType.TYPE_PRATICAL,
    },
  ],
};

const state = {
  tableLoading: false,
  registerCourse: template,
  allCourses: [],
  coursesHeaders: [
    {
      text: 'Nome',
      value: 'name',
      align: 'left',
      sortable: true,
    },
    {
      text: 'Tipo',
      value: 'type.name',
      align: 'left',
      sortable: true,
    },
    {
      text: 'Carga horária',
      value: 'workload',
      align: 'left',
      sortable: true,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allCourses = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (payload) {
      if (!!payload.files) {
        payload.classFiles = payload.files.filter(item => item.type === courseFilesType.TYPE_CLASS);
        if(payload.classFiles.length === 0) {
          payload.classFiles = template.classFiles;
        }

        payload.theoreticalFile = payload.files.filter(item => item.type === courseFilesType.TYPE_THEORETICAL);
        if(payload.theoreticalFile.length === 0) {
          payload.theoreticalFile = template.theoreticalFile;
        }

        payload.praticalFile = payload.files.filter(item => item.type === courseFilesType.TYPE_PRATICAL);
        if(payload.praticalFile.length === 0) {
          payload.praticalFile = template.praticalFile;
        }
      } else {
        payload.classFiles = template.classFiles;
        payload.theoreticalFile = template.theoreticalFile;
        payload.praticalFile = template.praticalFile;
      }

      state.registerCourse = cloneDeep(payload);

    } else {
      state.registerCourse = cloneDeep(template);
    }
  },
  CREATE_FILE(state) {
    let address = cloneDeep(template.classFiles[0]);
    state.registerCourse.classFiles.push(address);
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('courses', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, name, workload, type');

    return new Promise((resolve, reject) => {
      api.get('courses?' + new URLSearchParams(query).toString())
         .then(response => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('courses/' + id).then(response => {
        commit('SET', response.data);
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('courses/' + payload.class + '/files/' + payload.id)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('courses/' + payload.id, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('courses/' + id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  deleteFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.delete('courses/' + payload.class + '/files/' + payload.id)
         .then((response) => {
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
