import api from '@/plugins/api';
import { checkError, cloneDeep, isNullOrEmpty, parseAddressToApp, prepareApiParams } from 'erp-skeleton-library/utils';
import { serviceOrderPurpose, serviceOrderStatus } from '@/plugins/enums';

const template = {
  id: 0,
  status: 1,
  type: 1,
  number: null,
  internal: false,
  reference: null,
  supervisor: null,
  information: null,
  customer: null,
  address: null,
  date: null,
  evaluation: {},
  technicians: [
    {
      technician: null,
      main: true,
    },
  ],
  addresses: [
    {
      address: {
        zipCode: null,
        city: null,
        state: null,
        address: null,
        number: null,
      },
    },
  ],
};

const state = {
  tableLoading: false,
  allServiceOrders: [],
  allServiceOrdersTasks: [],
  registerServiceOrder: cloneDeep(template),
  allServiceOrderStatus: [
    {
      id: serviceOrderStatus.TYPE_REGISTERED,
      label: 'Registrada',
      color: '#4682b4',
    },
    {
      id: serviceOrderStatus.TYPE_IN_PROGRESS,
      label: 'Em execução',
      color: 'orange',
    },
    {
      id: serviceOrderStatus.TYPE_FINISHED,
      label: 'Finalizada',
      color: 'green',
    },
    {
      id: serviceOrderStatus.TYPE_REFUSED,
      label: 'Recusada',
      color: '#8A8060',
    },
    {
      id: serviceOrderStatus.TYPE_CANCELED,
      label: 'Cancelada',
      color: 'red',
    },
  ],
  allServiceOrderPurposes: [
    {
      id: serviceOrderPurpose.TYPE_INSTALLATION,
      label: 'Instalação',
    },
    {
      id: serviceOrderPurpose.TYPE_INFRASTRUCTURE,
      label: 'Infraestrutura',
    },
    {
      id: serviceOrderPurpose.TYPE_TECHNICAL_VISIT,
      label: 'Visita técnica',
    },
    {
      id: serviceOrderPurpose.TYPE_ADEQUACY,
      label: 'Adequação',
    },
  ],
  headers: [
    {
      text: 'Seq. APR',
      value: 'number',
      align: 'left',
    },
    {
      text: 'Status',
      value: 'status',
      align: 'left',
    },
    {
      text: 'Cliente',
      value: 'customer',
      align: 'left',
      sortable: false,
    },
    {
      text: 'OS de execução',
      value: 'reference',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Técnico',
      value: 'technician',
      align: 'left',
      sortable: false,
    },
    {
      text: 'Cadastro',
      value: 'createdAt',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Agendamento',
      value: 'date',
      align: 'center',
      sortable: false,
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allServiceOrders = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if (isNullOrEmpty(payload)) {
      state.registerServiceOrder = cloneDeep(template);
    } else {
      if(!isNullOrEmpty(payload.serviceOrder)) {
        payload.serviceOrder.startedAt = payload.serviceOrder.startedAt?.substring(0, 16);
        payload.serviceOrder.finishedAt = payload.serviceOrder.finishedAt?.substring(0, 16);
      }

      if ([serviceOrderPurpose.TYPE_ADEQUACY, serviceOrderPurpose.TYPE_INFRASTRUCTURE].includes(payload.purpose)) {
        payload.addresses = parseAddressToApp(payload);
      } else {
        payload.address = payload.address.id;
        payload.addresses = cloneDeep(template.addresses);
      }

      state.registerServiceOrder = cloneDeep(payload);
    }
  },
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('documents', payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload.apiParams, 'id, number, reference, technicians, status, customer, date, createdAt');

    if(!isNullOrEmpty(payload.filterFields)) {
      for(let filter in payload.filterFields) {
        if(['date', 'createdAt'].includes(filter)) {
          query[filter] = '{operator:BETWEEN|value:' + payload.filterFields[filter] + ',' + payload.filterFields[filter] + ' 23:59:59}';
        } else if (!['technicians{technicianMain}', 'technicians{technicianAny}'].includes(filter)) {
          query[filter] = payload.filterFields[filter];
        }
      }
    }

    return new Promise((resolve, reject) => {
      api.get('documents?' + new URLSearchParams(query).toString())
         .then(response => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch(error => {
           state.tableLoading = false;
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  listInProgress() {
    let query = prepareApiParams(null, 'id, number, reference, technicians, customer, date, address{city, state}, monitoring');
    query.status = serviceOrderStatus.TYPE_IN_PROGRESS;

    return new Promise((resolve, reject) => {
      api.get('documents?' + new URLSearchParams(query).toString())
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + id)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         }).catch(error => {
           console.log(error)
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readReport({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + payload + '/print')
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        console.log(error)
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('documents/' + payload.id, payload)
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readDocumentTaskPhoto({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + payload.document + '/tasks/' + payload.task + '/photos/' + payload.id )
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readDocumentPhoto({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + payload.document + '/photos/' + payload.id )
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  readEvaluationPhoto({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('documents/' + payload.document + '/evaluation/photos/' + payload.id )
         .then(response => {
           resolve(response.data);
         }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('documents/' + id)
         .then((response) => {
           resolve(response.data);
           ;
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
