import api from '../../../plugins/api';
import  { checkError, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  tableLoading: false,
  registerCourseType: {
    id: 0,
    name: null,
    legal: null,
  },
  allCoursesType: [],
  headers: [
    {
      text: 'Nome',
      value: 'name',
      align: 'left',
      sortable: true,
    },
    {
      text: 'Classificação',
      value: 'legal',
      align: 'left',
      sortable: true,
    },
  ],
  coursesTypeCategories: [
    {
      id: false,
      label: 'Técnico'
    },
    {
      id: true,
      label: 'Legal'
    }
  ]
};

const mutations = {
  LIST(state, payload) {
    state.allCoursesType = payload;
    state.tableLoading = false;
  },
  SET(state, payload) {
    if(!!payload) {
      state.registerCourseType = payload;
    } else {
      state.registerCourseType = {
        id: 0,
        name: null,
        legal: null
      }
    }
  }
};

const actions = {
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('courses/types', payload)
           .then(response => {
             resolve(response.data);
           }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload, 'id, name, legal');

    return new Promise((resolve, reject) => {
      api.get('courses/types?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  read({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.get('courses/types/' + id).then(response => {
        commit('SET', response.data);
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('courses/types/' + payload.id, payload).then(response => {
        resolve(response.data);
      }).catch(error => {
        checkError(error.response.status);
        reject(error.response);
      });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.delete('courses/types/' + id)
           .then((response) => {
             resolve(response.data);
           })
           .catch((error) => {
             checkError(error.response.status);
             reject(error.response);
           });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
