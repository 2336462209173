import api from '@/plugins/api';
import { checkError, prepareApiParams } from 'erp-skeleton-library/utils';
import { paymentMethodsType } from '@/plugins/enums';


const state = {
  allPaymentMethods: [],
};

const mutations = {
  LIST(state, payload) {
    state.allPaymentMethods = payload[0].terms;
  },
};

const actions = {
  listMethods({ commit }) {
    return new Promise((resolve, reject) => {
      let query = prepareApiParams(null, 'id, terms');
      query.type = paymentMethodsType.TYPE_METHOD_CREDIT;

      api.get('/payments/methods?' + new URLSearchParams(query).toString())
         .then(response => {
           commit('LIST', response.data.entities);
           resolve(response.data);
         })
         .catch((error) => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
