import api from '../../plugins/api';
import  { checkError, prepareApiParams } from 'erp-skeleton-library/utils';

const state = {
  tableLoading: false,
  allMonitoring: [
    {
      periodStart: '03-04-2021 08:53:05',
      monitoringTime: 1
    }
  ],
  headers: [
    {
      text: 'Iniciado em',
      align: 'left',
      value: 'periodStart',
    },
    {
      text: 'Tempo do monitoramento',
      align: 'left',
      value: 'monitoringTime',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allMonitoring = payload;
    state.tableLoading = false;
  },
};

const actions = {
  list({ commit }, payload) {
    state.tableLoading = true;
    let query = prepareApiParams(payload);

    return new Promise((resolve, reject) => {
      api.get('monitoring?' + new URLSearchParams(query).toString())
        .then(response => {
          commit('LIST', response.data.entities);
          state.tableLoading = false;
           resolve(response.data);
        })
        .catch(error => {
          state.tableLoading = false;
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  listUnansweredMonitoring() {
    return new Promise((resolve, reject) => {
      api.get('pra/monitoring?')
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
