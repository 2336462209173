import api from '../../plugins/api';
import { checkError } from 'erp-skeleton-library/utils';
import { statusClassStudent } from '@/plugins/enums';


const state = {
  tableLoading: false,
  allCertificates: [],
  headers: [
    {
      text: 'CPF',
      align: 'left',
      value: 'taxId',
    },
    {
      text: 'Aluno',
      align: 'left',
      value: 'student',
    },
    {
      text: 'Teórica',
      align: 'right',
      value: '',
    },
    {
      text: 'Prática',
      align: 'right',
      value: '',
    },
    {
      text: 'Compor.',
      align: 'right',
      value: '',
    },
    {
      text: 'Geral',
      align: 'right',
      value: '',
    },
    {
      text: 'Situação',
      align: 'right',
      value: '',
    },
  ],
  statusClassStudents: [
    {
      id: statusClassStudent.TYPE_APPROVED,
      label: 'Aprovado',
    },
    {
      id: statusClassStudent.TYPE_ABSENT,
      label: 'Ausente',
    },
    {
      id: statusClassStudent.TYPE_INSUFFICIENCY,
      label: 'Insuficiente',
    },
  ],
};

const mutations = {
  LIST(state, payload) {
    state.allCertificates = payload;
    state.tableLoading = false;
  },
};

const actions = {
  generateCertificationByStudent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes/' + payload.class + '/students/' + payload.id + '/certification')
         .then(response => resolve(response.data))
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  generateAllCertifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.post('classes/' + payload + '/certifications')
         .then(response => resolve(response.data))
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  requestAllCertifications({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('classes/' + payload + '/certifications/all')
         .then(response => resolve(response.data))
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  requestCertificationBack({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.get('classes/' + payload + '/certifications/back')
         .then(response => resolve(response.data))
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
