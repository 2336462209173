import Vue from 'vue';
import VueRouter from 'vue-router';
import { userType } from '@/plugins/enums';
import store from './store/store';
import { baseStore } from 'erp-skeleton-library/base-store/base-store';
import { hideFabButtons, isNullOrEmpty, verifyDebug } from 'erp-skeleton-library/utils';
import { usersTenants } from './plugins/enums';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/Home.vue'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR ],
          },
          component: () => import('./views/Dashboard.vue'),
        },
        {
          path: '/service-orders',
          name: 'service-order',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR ],
            pra: true,
          },
          component: () => import('./views/service-order/ServiceOrder.vue'),
        },
        {
          path: '/service-orders/new',
          name: 'service-orders-new',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/service-order/ServiceOrderRegister.vue'),
        },
        {
          path: '/service-orders/:id',
          name: 'service-orders-edit',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER, usersTenants.TYPE_TECHNICIAN, usersTenants.TYPE_SUPERVISOR ],
            pra: true,
          },
          component: () => import('./views/service-order/ServiceOrderRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/courses',
          name: 'courses',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/courses/Courses.vue'),
        },
        {
          path: '/register/courses/new',
          name: 'courses-new',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/courses/CoursesRegister.vue'),
        },
        {
          path: '/register/courses/:id',
          name: 'courses-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/courses/CoursesRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/config/courses/type',
          name: 'courses-type',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/courses/CoursesType.vue'),
        },
        {
          path: '/customers',
          name: 'customers',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/customers/Customer.vue'),
        },
        {
          path: '/customers/new',
          name: 'customer-register',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/customers/CustomerRegister.vue'),
        },
        {
          path: '/customers/:id',
          name: 'customer-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/customers/CustomerRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/pra/register/parties',
          name: 'parties',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/parties/Parties.vue'),
        },
        {
          path: '/pra/register/parties/new',
          name: 'parties-register',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/parties/PartiesRegister.vue'),
        },
        {
          path: '/pra/register/parties/:id',
          name: 'parties-edit',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/parties/PartiesRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/pra/register/tenant/collaborators',
          name: 'tenant-collaborators',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/tenant-collaborators/TenantCollaborator.vue'),
        },
        {
          path: '/pra/register/tenant/collaborators/new',
          name: 'tenant-collaborators-register',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/tenant-collaborators/TenantCollaboratorRegister.vue'),
        },
        {
          path: '/pra/register/tenant/collaborators/:id',
          name: 'tenant-collaborators-edit',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/tenant-collaborators/TenantCollaboratorRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/pra/register/technicians',
          name: 'technicians',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/technicians/Technician.vue'),
        },
        {
          path: '/pra/register/technicians/new',
          name: 'technicians-register',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/technicians/TechnicianRegister.vue'),
        },
        {
          path: '/pra/register/technicians/:id',
          name: 'technicians-edit',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/technicians/TechnicianRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/pra/register/supervisors',
          name: 'supervisors',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/supervisors/Supervisor.vue'),
        },
        {
          path: '/pra/register/supervisors/new',
          name: 'supervisors-register',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/supervisors/SupervisorRegister.vue'),
        },
        {
          path: '/pra/register/supervisors/:id',
          name: 'supervisors-edit',
          meta: {
            userType: [ userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/supervisors/SupervisorRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/students',
          name: 'students',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/students/Student.vue'),
        },
        {
          path: '/register/students/new',
          name: 'students-register',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/students/StudentRegister.vue'),
        },
        {
          path: '/register/students/:id',
          name: 'students-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/students/StudentRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/partners',
          name: 'partners',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/partners/Partner.vue'),
        },
        {
          path: '/register/partners/new',
          name: 'partners-register',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/partners/PartnerRegister.vue'),
        },
        {
          path: '/register/partners/:id',
          name: 'partners-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/partners/PartnerRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/instructors',
          name: 'instructors',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/instructors/Instructor.vue'),
        },
        {
          path: '/register/instructors/new',
          name: 'instructors-register',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/instructors/InstructorRegister.vue'),
        },
        {
          path: '/register/instructors/:id',
          name: 'instructors-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/instructors/InstructorRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/collaborators',
          name: 'collaborators',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/collaborators/Collaborator.vue'),
        },
        {
          path: '/register/collaborators/new',
          name: 'collaborators-register',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/collaborators/CollaboratorRegister.vue'),
        },
        {
          path: '/register/collaborators/:id',
          name: 'collaborators-edit',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/collaborators/CollaboratorRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/register/places',
          name: 'places',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
          },
          component: () => import('./views/Places.vue'),
        },
        {
          path: '/config/tasks',
          name: 'tasks',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/Tasks.vue'),
        },
        {
          path: '/classes',
          name: 'classes',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            student: true,
          },
          component: () => import('./views/classes/Classes.vue'),
        },
        {
          path: '/classes/new',
          name: 'classes-register',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            student: true,
          },
          component: () => import('./views/classes/ClassesRegister.vue'),
        },
        {
          path: '/classes/:id',
          name: 'classes-edit',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            student: true,
          },
          component: () => import('./views/classes/ClassesRegister.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            const students = Number.parseInt(route.query.students);
            const certifications = Number.parseInt(route.query.certifications);
            return { id, students, certifications };
          },
        },
        {
          path: '/classes/attendances/:id',
          name: 'classes-attendances',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            student: true,
          },
          component: () => import('./views/classes/ClassesAttendances.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/classes/grades/:id',
          name: 'classes-grades',
          meta: {
            userType: [ userType.TYPE_SYSTEM, userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            student: true,
          },
          component: () => import('./views/classes/ClassessGrades.vue'),
          props: (route) => {
            const id = Number.parseInt(route.params.id);
            return { id };
          },
        },
        {
          path: '/config/file-types',
          name: 'file-types',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT ],
          },
          component: () => import('./views/FileTypes.vue'),
        },
        {
          path: '/reports',
          name: 'reports',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/Reports.vue'),
        },
        {
          path: '/pra/reports',
          name: 'pra-reports',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_SUPPORT, userType.TYPE_USER ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
          },
          component: () => import('./views/Reports.vue'),
        },
        {
          path: '/profile',
          name: 'profile',
          meta: {
            userType: [ userType.TYPE_ADMIN, userType.TYPE_USER, userType.TYPE_SUPPORT, userType.TYPE_INSTRUCTOR ],
            tenantUserType: [ usersTenants.TYPE_ADMIN, usersTenants.TYPE_USER ],
            pra: true,
            student: true,
          },
          component: () => import('./views/Profile.vue'),
        },
      ],
    },
    {
      path: '*',
      name: 'login',
      component: () => import('./views/Index.vue'),
    },
  ],
});


router.beforeEach((to, from, next) => {
  hideFabButtons();
  verifyDebug();

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
      });
    } else if (!sessionStorage.getItem('tokenIsValid')) {
      store.dispatch('users/verifyUserStatus', baseStore.state.appUser.id)
           .then(() => {
             sessionStorage.setItem('tokenIsValid', 'true');
             next();
           });
    } else {
      if (navigationGuard(to.meta, baseStore.state.appUser)) {
        next();
      } else {
        next({ path: '/dashboard' });
      }
    }
  } else {
    if (store.getters.loggedIn) {
      next({
        path: '/dashboard',
      });
    } else {
      next();
    }
  }
});

function navigationGuard(pathMeta, appUser) {
  if (appUser.type !== userType.TYPE_USER) {
    if(pathMeta.hasOwnProperty('pra') && pathMeta.userType.includes(parseInt(appUser.type))) {
      return true;
    } else if (pathMeta.hasOwnProperty('pra')) {
      return (!isNullOrEmpty(appUser.tenants) && appUser.tenants[0].tenant.pra);
    } else {
      return pathMeta.userType.includes(parseInt(appUser.type));
    }
  }

  //Tenant (Type User)
  if(hasTenantPermission(pathMeta)) {
    if (pathMeta.hasOwnProperty('student') && appUser.student) {
      return true;
    }

    if(pathMeta.hasOwnProperty('pra') && pathMeta.tenantUserType.includes(store.getters.getUserType.tenantUserType)) {
      return (!isNullOrEmpty(appUser.tenants) && appUser.tenants[0].tenant.pra);
    } else if(!appUser.student) {
      return pathMeta.tenantUserType.includes(store.getters.getUserType.tenantUserType);
    } else if (!pathMeta.hasOwnProperty('pra')) {
      return pathMeta.userType.includes(parseInt(appUser.type));
    }
  }
}

function hasTenantPermission(pathMeta) {
  return pathMeta.tenantUserType;
}

export default router;
