import api from '@/plugins/api';
import { checkError, cloneDeep, prepareApiParams } from 'erp-skeleton-library/utils';
import { usersTenants } from '@/plugins/enums';

const state = {
  registerProfile: {
    id: null,
    name: null,
    email: null,
  },
};

const mutations = {
  SET(state, payload) {
    state.registerProfile = cloneDeep(payload);
  },
  CREATE_PHONE(state) {
    state.registerProfile.phones.push({ fullNumber: '' });
  },
  CREATE_ADDRESS(state) {
    state.registerProfile.addresses.push(
      {
        address: {
          zipCode: '',
          state: '',
          city: '',
          neighborhood: '',
          number: '',
          address: '',
          complement: '',
        },
      },
    );
  },
  DELETE_ADDRESS(state, index) {
    state.registerProfile.addresses.splice(index, 1);
  },
  DELETE_PHONE(state, index) {
    state.registerProfile.phones.splice(index, 1);
  },
};

const actions = {
  create({commit}, payload) {
    return new Promise((resolve, reject) => {
      api.post('users', payload)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  passwordReset({ commit }, id) {
    return new Promise((resolve, reject) => {
      api.post('users/' + id + '/password-reset')
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  list({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let query = prepareApiParams(payload, 'id, name, taxId');

      api.get('users?' + new URLSearchParams(query).toString())
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  listUsersFromCustomer({commit}, payload) {
    let query = prepareApiParams(payload.apiParams, 'id, name, email, tenants{type}');
    query['tenants{tenant}'] = payload.customerId;
    query['tenants{type}'] = '{' + usersTenants.TYPE_ADMIN + ', ' + usersTenants.TYPE_USER + '}';

    return new Promise((resolve, reject) => {
      api.get('users?' + new URLSearchParams(query).toString())
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          checkError(error.response.status);
          reject(error.response);
        });
    });
  },
  verifyUserStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('users/' + payload)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  read({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.get('users/' + payload)
         .then(response => {
           commit('SET', response.data);
           resolve(response.data);
         })
         .catch(error => {
           console.log(error);
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.id, payload)
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
  updateSignature({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api.put('users/' + payload.user + '/signature', { fileData: payload.fileData })
         .then(response => {
           resolve(response.data);
         })
         .catch(error => {
           checkError(error.response.status);
           reject(error.response);
         });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

